import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found/page-not-found.component';
import { PagesRoutingModule } from './pages-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PhotographyComponent } from './photo-page/photography/photography.component';
import { AboutComponent } from './about/about.component';
import { ComponentsModule } from '../components/components.module';
import { ProjectsComponent } from './project-page/projects/projects.component';
import { ProjectDetailComponent } from './project-page/project-detail/project-detail.component';
import { MarkdownModule } from 'ngx-markdown';
import { AlbumComponent } from './photo-page/album/album.component';
import { VideoComponent } from './video/video.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    HomeComponent,
    PhotographyComponent,
    AboutComponent,
    ProjectsComponent,
    PageNotFoundComponent,
    ProjectDetailComponent,
    AlbumComponent,
    VideoComponent,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    FontAwesomeModule,
    ComponentsModule,
    FormsModule,
    MarkdownModule.forChild(),
  ],
})
export class PagesModule {}
