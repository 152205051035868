import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  goToLink(url: string){
    window.open(url, "_blank");
  }
}
