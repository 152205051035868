<div class="modal-overlay" [ngClass]="{'visible': isModalVisible}" (click)="hideModal()">
  <div class="modal-container">
    <img class="overlay" src="{{selected_image}}" alt="Image">
  </div>
</div>

<div class="cover" style="background-image: url({{album.showcase}});">
  <div class="cover-detail">
    <h1 class="cover-collection-name has-text-weight-medium">{{album.title}}</h1>
    <h4 class="cover-collection-date">{{album.date | date:'MMMM d, y'}}</h4> <div class="cover-arrow">
    <a id="open" (click)="scroll(target)">OPEN</a>
    </div>
  </div>
  <div class="cover-logo-wrapper">
    <a routerLink="/">BOISEN</a>
  </div>
</div>
<div #target>
  <app-navbar></app-navbar>
    <div class="masonry-wrapper">
      <div class="masonry">
        <div class="masonry-item" *ngFor="let image of album.images; let i = index">
          <img class="masonry-content" src="{{image}}">
        </div>
    </div>
  </div>
</div>
