import { NgModule } from '@angular/core';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProjectComponent } from './project/project.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PhotoAlbumComponent } from './photo-album/photo-album.component';

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    ProjectComponent,
    PhotoAlbumComponent,
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: [],
  exports: [FooterComponent, NavbarComponent, ProjectComponent, PhotoAlbumComponent],
})
export class ComponentsModule {}
