<div style="background-color: #f8fafd">
  <header
    class="top-bar"
    style="
      border-top: 3px solid orange;
      position: fixed;
      width: 100%;
      z-index: 5050;
    "
  ></header>
  <div *ngIf="system.navBarToggle"><app-navbar></app-navbar></div>
  <div id="page-container">
    <div id="content-wrap">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>

