import { Component, HostListener, OnInit } from '@angular/core';
import ProjectJson from '../../../../assets/projects.json';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  isFilterVisible: boolean;

  projects = ProjectJson
  showProjects = this.projects['projects'];
  sortDirection: string = 'asc'; // 'asc' or 'desc'
  activeSortProperty: string = 'size'; // '', 'size', or 'date'
  tags: string[] = ['AAU-project', 'Python', 'C#', 'C', 'Java', 'Angular', 'Flutter', 'Machine-learning', 'Flask', 'Discord', 'Reddit', 'Instagram', 'Notion', 'Strava', "SQL", "NoSQL", "API", "JSON", "TypeScript", "Dart", "GoogleCalendar", "GoogleMaps"];
  database: string[] = ["SQL", "NoSQL", "JSON"];
  apis: string[] = ['Discord', 'Instagram', 'Reddit', 'Notion', 'Strava', "GoogleCalendar", "GoogleMaps"];
  frameworks: string[] = ['AAU-project', 'Angular', 'Flutter', 'Machine-learning', 'Flask'];
  languages: string[] = ['Python', 'C#', 'C', 'Java', 'TypeScript', 'Dart'];
  sizes: string[] = ['Big', 'Small'];
  selectedLanguages = new Map<string, boolean>();

  constructor(private route: ActivatedRoute, private router: Router) {
    this.isFilterVisible = window.innerWidth >= 1023;
  }

  ngOnInit(): void {

    //   this.router.events.subscribe((evt) => {
    //     if (!(evt instanceof NavigationEnd)) {
    //         return;
    //     }
    //     window.scrollTo(0, 0)
    // });

    for (const tag of this.tags) {
      this.selectedLanguages[tag] = false;
    }

    for (const tag of this.sizes) {
      this.selectedLanguages[tag] = false;
    }

    // console.log(this.selectedLanguages);
    this.route.queryParams.subscribe(params => {
      let filter = params['filter'];

      if (filter == 'C') {
        filter = 'C#';
      }
      // console.log(filter)
      this.selectedLanguages[filter] = true;
      // console.log(this.selectedLanguages)
      this.filterProjects();
    });
  }

  toggleFilter() {
    this.isFilterVisible = !this.isFilterVisible;
    // console.log(this.isFilterVisible);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isFilterVisible = event.target.innerWidth >= 1023;
  }

  isLargeScreen(): boolean {
    return window.innerWidth > 1023;
  }

  toggleSortDirection() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  }

  sortProjects(property: string, direction: string) {
    this.activeSortProperty = property;
    const sortOrder = direction === 'asc' ? 1 : -1;

    this.showProjects = this.showProjects.sort((a, b) => {
      let aValue = a[property];
      let bValue = b[property];

      if (property === 'date') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      } else if (property === 'size') {
        aValue = parseInt(aValue);
        bValue = parseInt(bValue);
      }

      if (aValue < bValue) {
        return -1 * sortOrder;
      } else if (aValue > bValue) {
        return 1 * sortOrder;
      } else {
        return 0;
      }
    });
  }

  filterProjects() {
    this.showProjects = this.projects['projects'].filter((project) => {
      let sizeMatch = false;
      let tagMatch = false;

      if (this.selectedLanguages['Big'] && project.size < 100) {
        sizeMatch = true;
      } else if (this.selectedLanguages['Small'] && project.size >= 100) {
        sizeMatch = true;
      } else if (!this.selectedLanguages['Big'] && !this.selectedLanguages['Small']) {
        sizeMatch = true;
      }

      for (const tag of project.tags) {
        if (this.selectedLanguages[tag]) {
          tagMatch = true;
          break;
        }
      }

      const activeTags = this.tags.filter(tag => this.selectedLanguages[tag]);
      tagMatch = activeTags.every(tag => project.tags.includes(tag));

      if (!this.tags.some(tag => this.selectedLanguages[tag])) {
        tagMatch = true;
      }

      return sizeMatch && tagMatch;
    });

    this.sortProjects(this.activeSortProperty, this.sortDirection);
  }

  getProjectCountByTag(tag: string): number {
    return this.showProjects.filter(project => project.tags.includes(tag)).length;
  }

  getProjectCountBySize(size: string): number {
    return this.showProjects.filter(project => (size === 'Big' ? project.size <= 100 : project.size > 100)).length;
  }

  applyFilter(language) {
    this.selectedLanguages[language] = !this.selectedLanguages[language];
    this.filterProjects();
  }
}
