import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-photo-album',
  templateUrl: './photo-album.component.html',
  styleUrls: ['./photo-album.component.css'],
  providers: [DatePipe]
})
export class PhotoAlbumComponent implements OnInit {
  @Input()
  album: any

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.album.date = this.datePipe.transform(this.album.date, 'MMMM/d/y');
    // console.log(this.album.date);
  }
}
