
<div class="image-album has-text-centered">
  <a [routerLink]="['/projects', project.title]">
    <div class="image-holder" style="background-image: url({{project.showcase}});">
      <!-- <img class="static" src="{{project.showcase}}"><img class="active" src="{{project.gifSrc}}"> -->
    </div>
    <p class="has-text-weight-semibold" id="title">{{project.title}}</p>
    <p id="date">{{project.date | date:'MMMM y'}}</p>
    <i class="tag is-white" style="color: #3273dc; font-weight: bold;" *ngFor="let tag of project.tags.slice(0, 4); let i = index">
      {{tag}}
    </i>
    <div class="links columns is-centered is-multiline is-mobile">
      <div class="column is-one-quarter" *ngIf="project.resp">
        <a data-toggle="tooltip" title="View repository!" (click)="linkWithoutPropagation($event, project.resp)">
          <i class="fas fa-code-branch"></i>
        </a>
      </div>
      <div class="column is-one-quarter" *ngIf="project.site">
        <a data-toggle="tooltip" title="See it for yourself!" (click)="linkWithoutPropagation($event, project.site)">
          <i class="fas fa-paper-plane"></i>
        </a>
      </div>
      <div class="column is-one-quarter">
        <a data-toggle="tooltip" title="Read more!">
          <i class="fas fa-book-open"></i>
        </a>
      </div>
    </div>
  </a>
</div>


<!-- 
<div class="image-album has-text-centered">
  <a [routerLink]="['/projects', project.title]">
    <div class="image-holder" style="background-image: url({{project.showcase}});">
      <img class="static" src="{{project.showcase}}"><img class="active" src="{{project.gifSrc}}">
    </div>
    <p class="has-text-weight-semibold" id="title">{{project.title}}</p>
    <p id="date">{{project.date | date:'MMMM y'}}</p>
  </a>
</div> -->
