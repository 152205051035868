<div style="font-weight: 300;" class="columns">
  <div style="padding-left: 50px;" class="column is-2">
    <!-- Add a button to toggle the filter section on mobile devices -->
    <div class="filter-toggle-button">
      <br>
      <button class="filter-toggle-button button is-info is-light" (click)="toggleFilter()">
        {{ isFilterVisible ? 'Hide filter' : 'Show filter' }}
      </button>
    </div>
    <!-- Wrap the filter section with a div having class filter-container -->
    <div class="filter-container" *ngIf="isFilterVisible || isLargeScreen()">
      <h1 style="font-weight: bold; font-size: 2em;">Filter</h1>
    <div style="font-weight: bold; font-size: 1em;">Sort</div>
    <div style="cursor: pointer;" (click)="toggleSortDirection(); sortProjects('size', sortDirection)">
      <i class="fa-solid fa-arrow-up fa-xs" *ngIf="activeSortProperty === 'size' && sortDirection === 'asc'"></i>
      <i class="fa-solid fa-arrow-down fa-xs" *ngIf="activeSortProperty === 'size' && sortDirection === 'desc'"></i> Size
    </div>
    <div style="cursor: pointer;" (click)="toggleSortDirection(); sortProjects('date', sortDirection)">
      <i class="fa-solid fa-arrow-up fa-xs" *ngIf="activeSortProperty === 'date' && sortDirection === 'asc'"></i>
      <i class="fa-solid fa-arrow-down fa-xs" *ngIf="activeSortProperty === 'date' && sortDirection === 'desc'"></i> Year
    </div>
    <h1 style="font-weight: bold; font-size: 1em;">Size</h1>
    <div *ngFor="let size of sizes">
      <div style="cursor: pointer;" (click)="applyFilter(size)" class="checkbox-option">
        <input [(ngModel)]="selectedLanguages[size]"  type="checkbox">
        <div>({{ getProjectCountBySize(size) }}) {{ size }}</div>
      </div>
    </div>
    <h1 style="font-weight: bold; font-size: 1em;">Language</h1>
    <div *ngFor="let tag of languages">
      <div style="cursor: pointer;" (click)="applyFilter(tag)" class="checkbox-option">
        <input [(ngModel)]="selectedLanguages[tag]"  type="checkbox">
        <div>({{ getProjectCountByTag(tag) }}) {{ tag }}</div>
      </div>
    </div>
    <h1 style="font-weight: bold; font-size: 1em;">Framework</h1>
    <div *ngFor="let tag of frameworks">
      <div style="cursor: pointer;" (click)="applyFilter(tag)" class="checkbox-option">
        <input [(ngModel)]="selectedLanguages[tag]"  type="checkbox">
        <div>({{ getProjectCountByTag(tag) }}) {{ tag }}</div>
      </div>
    </div>
    <h1 style="font-weight: bold; font-size: 1em;">API</h1>
    <div *ngFor="let tag of apis">
      <div style="cursor: pointer;" (click)="applyFilter(tag)" class="checkbox-option">
        <input [(ngModel)]="selectedLanguages[tag]"  type="checkbox">
        <div>({{ getProjectCountByTag(tag) }}) {{ tag }}</div>
      </div>
    </div>
    <h1 style="font-weight: bold; font-size: 1em;">Database</h1>
    <div *ngFor="let tag of database">
      <div style="cursor: pointer;" (click)="applyFilter(tag)" class="checkbox-option">
        <input [(ngModel)]="selectedLanguages[tag]"  type="checkbox">
        <div>({{ getProjectCountByTag(tag) }}) {{ tag }}</div>
      </div>
    </div>
  </div>
  <!-- Other columns content here -->
</div>

<div class="column">
  <div class="container" style="width: 75%; margin-left: 2rem; margin-top: 2rem">
    <div class="columns is-centered is-multiline">
      <div class="column is-one-third zoom" *ngFor="let project of showProjects; let i = index">
        <app-project [project]="project"></app-project>
      </div>
    </div>
  </div>
</div>

<!-- <div class="container">
  <div class="container" style="width: 75%; margin-top: 2rem">
    <div class="columns is-centered is-multiline">
      <div class="column is-one-third" *ngFor="let project of projects.projects; let i = index" [attr.data-index]="i">
        <div style="animation: fadeIn 0.5s ease-in-out forwards {{i/8}}s; opacity: 0;">
          <app-project [project]="project"></app-project>
      </div>
    </div>
  </div>
</div> -->
