import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import PhotosJson from '../../../../assets/photos.json';

@Component({
  selector: 'app-photography',
  templateUrl: './photography.component.html',
  styleUrls: ['./photography.component.css']
})
export class PhotographyComponent implements AfterViewInit   {
  photos = PhotosJson;
  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;

  constructor() { 
    this.photos = this.photos.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
  }

  ngAfterViewInit(): void {
    this.videoPlayer.nativeElement.muted = true; // Ensure the video is muted
    this.videoPlayer.nativeElement.play() // Attempt to play the video
      .then(() => {
        console.log('Video playback started');
      })
      .catch((error) => {
        // Video playback failed
        console.error('Error attempting to play video:', error);
      });
  }


  onVideoLoaded() {
    this.videoPlayer.nativeElement.play().catch(e => {
      console.log(e)
      console.error('Autoplay was prevented.');
    });
  }
}
