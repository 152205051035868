import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  public name: string;
  public email: string;
  public msg: string;

  constructor(public util: UtilsService) { }

  ngOnInit(): void {
  }

  public SubmitForm() {
    // console.log(this.name);
  }
}
