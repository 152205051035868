import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SystemService } from 'src/app/services/system.service';
import PhotosJson from '../../../../assets/photos.json';

@Component({
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.css']
})
export class AlbumComponent implements OnDestroy {
  photosJson = PhotosJson
  album: { title: string; date: string; showcase: string; images: string[];};
  selected_image: any;
  isModalVisible = false;

  constructor(public system: SystemService, private route : ActivatedRoute, private elRef: ElementRef) {
    this.system.navBarToggle = false;

    this.photosJson.forEach(album => {
      if (album.title == this.route.snapshot.params.album){
        this.album = album;
        return;
      }
    });
   }

   ngOnInit(): void {
    setTimeout(() => {
      const images = this.elRef.nativeElement.querySelectorAll('img');
      images.forEach(img => {
        if (img.classList.contains('overlay')) {
          return;
        }
        const wrapper = document.createElement('a');
        img.addEventListener('click', () => this.showModal(img.src));
        img.parentNode.insertBefore(wrapper, img);
        wrapper.appendChild(img);
      });
    }, 250);
  }

  hideModal(): void {
    this.isModalVisible = false;
  }
  
  showModal(image_path): void {
    this.isModalVisible = true;
    this.selected_image = image_path;
  }

   ngOnDestroy() {
    this.system.navBarToggle = true;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
