
<div class="container">
  <div class="container" style="width: 75%; margin-top: 2rem">
    <div class="columns is-centered is-multiline">
      <div class="page-container">
        <div class="left">
          <div class="container short-description">
            <h1 class="title is-size-4">
              Hi there!
            </h1>
            <h2 class="subtitle">I'm Magnus and currently a 26-year-old software engineer. I graduated with a Masters in Software Engineering in 2021. I enjoy exploring new ideas and possibilities through development. When I'm not coding, I love spending time with my friends and family, as well as capturing memories through photography and videography, and get my adrenaline pumping by flying FPV drones. I'm always seeking out new challenges and opportunities to learn, which is why I'm always working on new projects. Please take a moment to check out my portfolio page to see some of my recent work!</h2>
            <h2>
              Have a question about some of my work or want to discuss a project? Send me a message on <a (click)="util.goToLink('https://www.linkedin.com/in/magnus-boisen-826601134/')">LinkedIn</a> or contact me through my email
              <a href="mailto:magnus@boisen.me" target="_blank">magnus@boisen.me</a>

            </h2>
          </div>
          <br>
          <br>
          <img src="/assets/svg/people_search.svg" id="svg-discussion">
      </div>
    </div>
  </div>
</div>



