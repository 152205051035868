<footer id="footer" style="background-color: #f8fafd;">
<div class="has-text-centered text">
    <p style="color: #99a5b1;">Design, code & photography by Magnus Boisen 2023</p>
    <div class="has-text-centered has-text-grey-darker">
      <a routerLink='/'> home </a>
      <a routerLink='/projects'> projects </a>
      <a routerLink='/photography'> photography </a>
      <a routerLink='/about'> about </a>
    </div>
  </div>
</footer>
