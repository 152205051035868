<div class="navbar-menu is-active has-dropdown is-hoverable">
  <div class="navbar-brand">
    <a routerLink='/' class="navbar-item">
      <img src="assets/logo.png" width="28" height="28">
    </a>

    <a (click)="toggleNavbar()" role="button" #navBurger class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu" #navMenu>
    <div class="navbar-start">
      <a (click)="toggleNavbar()" class="navbar-item" routerLink='/' routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }"> home </a>
      <a (click)="toggleNavbar()" class="navbar-item" routerLink='/projects' routerLinkActive="active-link"> projects </a>
      <a (click)="toggleNavbar()" class="navbar-item" routerLink='/photography' routerLinkActive="active-link"> photography </a>
      <a (click)="toggleNavbar()" class="navbar-item" routerLink='/about' routerLinkActive="active-link"> about </a>
    </div>
    <div class="navbar-end has-text-centered">
      <div class="columns is-vcentered is-multiline is-mobile">
        <div class="column is-one-quarter">
          <a (click)="util.goToLink('https://github.com/boisenme')"><i class="fab fa-github"></i></a>
        </div>
        <div class="column is-one-quarter">
          <a (click)="util.goToLink('https://www.linkedin.com/in/magnus-boisen-826601134/')"
            ><i class="fab fa-linkedin-in"></i
          ></a>
        </div>
        <div class="column is-one-quarter">
          <a href="mailto:magnus@boisen.me"><i class="far fa-envelope"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>

