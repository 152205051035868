import { Component, OnInit, Input, ElementRef  } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { ActivatedRoute } from '@angular/router';
import {Router, NavigationEnd} from '@angular/router';
import ProjectJson from '../../../../assets/projects.json';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent implements OnInit {
  @Input()
  url = "assets/js/tooltip.js";
  project: any
  headers: any[] = [];
  selected_image: any;
  isLoading = false;

  constructor(private elRef: ElementRef, private router: Router, public util: UtilsService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.project = ProjectJson.projects.find(p => p.title === val.toString().split('/')[2].split("'")[0]);
        setTimeout(() => {
          const fragment = this.router.parseUrl(this.router.url).fragment;
          if (fragment) {
            const h5Elements = this.elRef.nativeElement.querySelectorAll('h5');
            for (let i = 0; i < h5Elements.length; i++) {
              const header = h5Elements[i].textContent.trim();
              if (header.length > 0) {
                const headerWithoutSpaces = header.replace(/\s/g, '');
                if (headerWithoutSpaces === fragment) {
                  h5Elements[i].scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              }
            }
          }
        }, 250);
      }
    });
   }

  ngOnInit(): void {
    this.isLoading = true;
    window.scrollTo(0, 0)

    this.loadScript();
    setTimeout(() => {

      // const images = this.elRef.nativeElement.querySelectorAll('img');
      // images.forEach(img => {
      //   if (img.classList.contains('overlay')) {
      //     return;
      //   }
      //   const wrapper = document.createElement('a');
      //   img.addEventListener('click', () => this.showModal(img.src));
      //   img.parentNode.insertBefore(wrapper, img);
      //   wrapper.appendChild(img);
      // });

      const h5Elements = this.elRef.nativeElement.querySelectorAll('h5');

      for (let i = 0; i < h5Elements.length; i++) {
        const header = h5Elements[i].textContent.trim();
        if (header.length > 0) {
          const headerWithoutSpaces = header.replace(/\s/g, '');
          this.headers.push([header, headerWithoutSpaces]);
        }
      }
    }, 150);
   
  }

  isModalVisible = false;

  showModal(image_path): void {
    this.isModalVisible = true;
    this.selected_image = image_path;
  }
  

  hideModal(): void {
    this.isModalVisible = false;
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public linkWithoutPropagation(event: Event, link) {
    event.stopPropagation();
    event.preventDefault();
    this.util.goToLink(link);
  }

  onMarkdownLoaded(event: Event) {
    const contentElement = document.getElementById('content');
    const images = contentElement.getElementsByTagName('img');
    for (let i = 0; i < images.length; i++) {
      images[i].style.height = '20vw';
      images[i].style.objectFit = 'contain';
      images[i].classList.add('img-content');

      if (images[i].classList.contains('overlay')) {
        return;
      }
      const wrapper = document.createElement('a');
      images[i].addEventListener('click', () => this.showModal(images[i].src));
      images[i].parentNode.insertBefore(wrapper, images[i]);
      wrapper.appendChild(images[i]);
    }

    this.isLoading = false;
  }

  public loadScript() {
    let node = document.createElement("script");
    node.src = this.url;
    node.type = "text/javascript";
    node.async = true;
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  ngAfterViewInit() {
    // const currentRoute = this.route.snapshot.routeConfig.path;
    // console.log(currentRoute);

  
  }
}
