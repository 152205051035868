<!-- <div class="wrapper">
  <div class="container">
    <div id="toc-container">
      <h1 style="font-weight: bold;">Table of Contents</h1>
      <ul>
        <li><a href="projects/travelovernight#audience">Section 1</a></li>
        <li><a href="#section2">Section 2</a></li>
        <li><a href="#section3">Section 3</a></li>
      </ul>
    </div>
    <div class="content" id="content">
      <markdown emoji lineNumbers [start]="1" [src]="'../../../assets/projects/travelovernight.md'"></markdown>
    </div>
  </div>
</div> -->

<div class="modal-overlay" [ngClass]="{'visible': isModalVisible}" (click)="hideModal()">
  <div class="modal-container">
    <img class="overlay" src="{{selected_image}}" alt="Image">
  </div>
</div>

<div class="info" style="padding-left: 50px;">
  <h1 style="font-size: 2em; font-weight: 600; text-align: left;">{{capitalizeFirstLetter(project.title)}}</h1>
  <p id="date">{{project.date | date:'MMMM y'}}</p>
  <i class="tag" style="color: #3273dc; background-color: #f8fafd; font-weight: bold; padding-left: 0px;" *ngFor="let tag of project.tags; let i = index">
    <a href="projects?filter={{tag}}">{{tag}}</a>
  </i>
  <div class="links is-mobile">
    <div class="is-one-fifth">
      <a style="padding-right: 10px;" data-toggle="tooltip" title="View repository!" (click)="linkWithoutPropagation($event, project.resp)" *ngIf="project.resp">
        <i class="fas fa-code-branch"></i>
      </a>
      <a style="padding-right: 10px;" data-toggle="tooltip" title="See it for yourself!" (click)="linkWithoutPropagation($event, project.site)" *ngIf="project.site">
        <i class="fas fa-paper-plane"></i>
      </a>
      <a style="padding-right: 10px;" data-toggle="tooltip" title="Read more!">
        <i class="fas fa-book-open"></i>
      </a>
    </div>
  </div>
</div>

<div class="wrapper">
  <div id="toc-container">
    <h1 style="font-weight: bold;">Table of Contents</h1>
    <ul>
      <li *ngFor="let header of headers"><a href="projects/{{project.title}}#{{header[1]}}">{{ header[0] }}</a></li>

    </ul>
  </div>
  <div class="content" id="content">
    <markdown emoji lineNumbers [start]="1" [src]="'../../../assets/projects/' + project.title + '/' + project.title + '.md'" (load)="onMarkdownLoaded($event)"></markdown>
  </div>
</div>
<br>
<br>

<div *ngIf="isLoading" class="loading-icon-container">
  <h1>Loading images...</h1>
  <i class="fas fa-spinner fa-pulse"></i>
</div>


<!-- 
<div class="container">
  <div class="container" style="width: 75%; margin-top: 2rem">
    <div class="content">
    </div>
  </div>
</div>
<div></div>>
<div></div>> -->