<div class="home container">
  <div class="columns">
    <div class="column">
      <section class="hero">
        <div class="hero-body">
          <div class="container">
            <h1 class="title is-size-2">
              <p class="text">
                Hello, I'm
                <span class="highlight">Magnus Boisen</span>.
              </p>
            </h1>
            <h2 class="subtitle">
              <p class="text">Software Developer</p>
            </h2>
            <div class="container short-description">
              <h1 class="title is-size-4">
                With 7 years of experience designing,
                developing and deploying software systems.
              </h1>
              <h2 class="subtitle">
                Holding a Master's degree in Software Engineering from Aalborg University, I bring experience from both professional work and personal projects. My passion lies in automating processes, optimizing systems for efficiency, and architecting backend structures that are designed for scalability and ease of managing.<br><br>You can view my 
                <a routerLink="/projects">work</a>, see my
                <!-- <a (click)="util.goToLink('https://github.com/boisenme')">GitHub</a>, find me on -->
                <a (click)="util.goToLink('https://www.linkedin.com/in/magnus-boisen-826601134/')">LinkedIn</a>, or <a routerLink="/about">contact me.</a> 
              </h2>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="column">
      <section class="hero">
        <div class="hero-body">
          <div class="card-image mt-4">
            <figure
              class="image is-centered is-inline-block image-me"
              style="width: 40%"
            >
              <img src="/../assets/me.png" style="border-radius: 2%;"/>
            </figure>
          </div>
        </div>
      </section>
    </div>
  </div>

</div>
