<section class="hero">
  <div class="hero-body">
    <div class="container">
      <h1 class="title is-size-2">
        <p class="text">
          Hello there, sadly there is
          <span class="highlight">nothing</span> here.
        </p>
      </h1>
      <h2 class="subtitle">
        <p class="text">it is okay</p>
      </h2>
      <figure
        class="image is-centered is-inline-block image-me"
        style="width: 40vh"
      >
        <img src="/../assets/404.png" />
      </figure>
    </div>
  </div>
</section>
