<div class="container">
  <div class="full-width-video-container is-centered">
    <a href="assets/photos/fpv_summer_2020.mp4">
      <video #videoPlayer (loadeddata)="onVideoLoaded()" autoplay loop muted class="video-background">
        <source src="assets/photos/fpv_summer.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </a>
  </div>
  <div class="container" style="width: 75%; margin-top: 2rem">
    <div class="columns is-centered is-multiline">
      <div class="column is-one-third" *ngFor="let photoAlbum of photos; let i = index">
        <app-photo-album [album]="photoAlbum"></app-photo-album>
      </div>
    </div>
  </div>
</div>