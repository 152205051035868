import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found/page-not-found.component';
import { HomeComponent } from './home/home/home.component';
import { ProjectsComponent } from './project-page/projects/projects.component';
import { PhotographyComponent } from './photo-page/photography/photography.component';
import { AboutComponent } from './about/about.component';
import { ProjectDetailComponent } from './project-page/project-detail/project-detail.component';
import { AlbumComponent } from './photo-page/album/album.component';
import { VideoComponent } from './video/video.component';


// All paths starts from root (/)
const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'projects', component: ProjectsComponent},
  {path: 'projects/:project', component: ProjectDetailComponent},
  {path: 'photography', component: PhotographyComponent},
  {path: 'photography/:album', component: AlbumComponent},
  {path: 'about', component: AboutComponent},
  {path: 'video', component: VideoComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
