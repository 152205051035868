import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  url = "assets/js/tooltip.js";

  @Input()
  project: any
  loadAPI: Promise<unknown>;

  constructor(public util: UtilsService) {
  }

  ngOnInit(): void {
    this.loadAPI = new Promise(resolve => {
      this.loadScript();
    });
  }

  public linkWithoutPropagation(event: Event, link) {
    event.stopPropagation();
    event.preventDefault();
    this.util.goToLink(link);
  }

  public loadScript() {
    let node = document.createElement("script");
    node.src = this.url;
    node.type = "text/javascript";
    node.async = true;
    document.getElementsByTagName("head")[0].appendChild(node);
  }
}
